.ClosedJobTable {
    margin-bottom: 30px;
}

.ClosedJobTable__heading {
    font-size: large;
    font-weight: bold;
    margin: 30px 0 10px 0;
}
.employee__icon__green{
    background-color: green;
    height: 50%;
    border-radius: 50%;
    margin: 0 auto;
}
.employee__icon__red{
    background-color: red;
    height: 47%;
    border-radius: 50%;
    margin: 0 auto;

}
.ClosedJobTable__pagination {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;
}

.ClosedJobTable__pagination__rows {
    margin: 10px 15px 0 0px;
    padding: 4px 15px 0 10px;
    width: fit-content;
    border-right: 1px solid lightgray;
    min-height: 35px;
    max-height: 35px;
    overflow-y: hidden;
}

.ClosedJobTable__pagination__page {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    width: fit-content;
    margin: 10px 0 0 0;
}

.ClosedJobTable__pagination__page__num {
    margin: 0px 5px 0px 5px;
    border-radius: 3px;
    min-height: 24px;
    max-height: 24px;
    border: 1px solid lightgray;
    padding: 5px;
    color:gray;
    font-size: medium;
}