.JobApplication {
  margin: auto;
}

.JobApplication__header {
  font-size: xx-large;
  color: #1976d2;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;
  max-width: 80%;
  min-width: 80%;
}

.JobApplication__box {
  margin: 0 auto;
  max-width: 80%;
  min-width: 80%;
}

.JobApplication__text__box {
  margin-bottom: 30px;
  padding: 2%;
  border: 1px solid lightgray;
}

.JobApplication__text__container {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}
.JobApplication__text__container__3{
  display: flex;
  padding: 5px;

}
.JobApplication__text__container__2 {
  margin-top: 20px;
  padding: 5px;
}

.JobApplication__text__title {
  min-width: 12%;
  max-width: 12%;
  font-size: large;
  font-weight: bolder;
  border-right: 1px solid lightgray;
  color: #1976d2;
}

.JobApplication__text__title__2 {
  font-size: large;
  font-weight: bolder;
  color: #1976d2;
}

.JobApplication__text {
  min-width: 35%;
  max-width: 35%;
  font-size: large;
  text-align: left;
  background-color: rgba(173, 216, 230, 0.497);
  border-radius: 2px;
  padding: 7px;
}
.JobApplication__text__3{
  min-width: 35%;
  max-width: 35%;
  font-size: large;
  text-align: left;
  background-color: rgba(173, 216, 230, 0.497);
  border-radius: 2px;
  padding: 7px;
  margin-left: 1%;
}
.JobApplication__text__2 {
  font-size: large;
  text-align: left;
  background-color: rgba(173, 216, 230, 0.4);
  border-radius: 2px;
  padding: 10px 20px;
  margin-top: 15px;
  white-space: pre-wrap;
}





.JobApplication__edit__box {
  margin-bottom: 30px;
  padding: 2%;
  border: 1px solid lightgray;
}

.JobApplication__edit__container {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.JobApplication__edit__container__2 {
  margin-top: 20px;
  padding: 5px;
}
.JobApplication__edit__container__3{
  display: flex;
  padding: 5px;

}
.JobApplication__edit__title {
  min-width: 12%;
  max-width: 12%;
  font-size: large;
  font-weight: bolder;
  border-right: 1px solid lightgray;
  color: #1976d2;
}

.JobApplication__edit__title__2 {
  font-size: large;
  font-weight: bolder;
  color: #1976d2;
}

.JobApplication__edit {
  min-width: 35%;
  max-width: 35%;
  font-size: large;
  text-align: left;
  background-color: rgba(173, 216, 230, 0.497);
  border-radius: 2px;
  padding: 7px;
}
.JobApplication__edit__3{
  min-width: 36.5%;
  max-width: 36.5%;
  font-size: large;
  text-align: left;
  background-color: rgba(173, 216, 230, 0.497);
  border-radius: 2px;
  padding: 10px;
  margin-left: 0.7%;
}

.JobApplication__edit__2 {
  font-size: large;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  background-color: rgba(173, 216, 230, 0.4);
  border-radius: 2px;
  padding: 10px 20px;
  margin-top: 15px;
  white-space: pre-wrap;
  min-width: 95%;
  min-height: 120px;
}

.export__btn {
  margin: 0 auto;
  display: flex;
  max-width: 80%;
  min-width: 80%;
  align-items: center;
  justify-content: right;
}
.export__btn__loading {
  cursor: not-allowed;
  background-color: grey;
  color: white;
  font-size: medium;
  border: none;
  padding: 7px 1%;
  text-align: center;
  border-radius: 5px;
  margin: 0 8% 0 73%; 
  max-width: 15%;
  min-width: 15%;
}

@media only screen and (max-width: 470px) {
  .JobApplication__header {
    font-weight: 600;
    display: block;
  }
}

@media only screen and (max-width: 1100px) {

  .JobApplication__text__container {
    display: block;
  }

  .JobApplication__text__title {
    min-width: none;
    max-width: none;
    margin-top: 15px;

  }

  .JobApplication__text {
    padding: 10px 20px;
    margin-top: 15px;
    white-space: pre-wrap;
    min-width: none;
    max-width: none;
  }

  .JobApplication__Tabs .css-heg063-MuiTabs-flexContainer {
    overflow: scroll;
  }
}