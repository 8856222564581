.Button{
	margin-right: 19px;

}

.button {
	pointer-events: auto;
	cursor: pointer;
    background-color: white;
	color: #0BA1AE;
    border: 1px solid #0BA1AE;
	border: none;
	padding: 1.5rem 2rem;
	margin: 0;
	font-family: inherit;
	font-size: large;
	position: relative;
	display: inline-block;
}

.button::before,
.button::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.content__item::before {
	color: var(--color-number);
	position: absolute;
	top: 0;
	left: 0;
	content: counters(itemcounter, ".", decimal-leading-zero);
}
.button--bestia {
	font-family: freight-display-pro, serif;
	font-size: 1.15rem;
	color: #fff;
	background: none;
	padding: 0;
}

.button--bestia .button__bg {
	text-align: center;
	color: #000;
	top: 0;
	left: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: white;
	color: #0BA1AE;
    border: 1px solid #0BA1AE;	border-radius: 0.85rem;
	overflow: hidden;
	transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
}

.button--bestia:hover .button__bg {
	transform: scale3d(1.2,1.2,1);
}

.button--bestia .button__bg::before,
.button--bestia .button__bg::after {
	content: '';
	position: absolute;
	background: #0BA1AE;
}

.button--bestia .button__bg::before {
	width: 110%;
	height: 0;
	padding-bottom: 110%;
	top: 50%;
	left: 50%;
	border-radius: 50%;
	transform: translate3d(-50%,-50%,0) scale3d(0,0,1);
}

.button--bestia:hover .button__bg::before {
	transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
	transform: translate3d(-50%,-50%,0) scale3d(1,1,1);
}

.button--bestia .button__bg::after {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: opacity 0.3s;
}

.button--bestia:hover .button__bg::after {
	opacity: 1;
	transition-duration: 0.01s;
	transition-delay: 0.3s;
}

.button--bestia span {
	font-size: large;
	color: #0BA1AE;
	text-decoration: none;
	display: block;
	position: relative;
	padding: 0.4rem 2rem;
}
.button--bestia span:hover{
	color: white;
}
@media only screen and (max-width: 600px){
	.button--bestia span {
		font-size: medium;
		color: #0BA1AE;
		text-decoration: none;
		display: block;
		position: relative;
		padding: 0.4rem 1rem;
	}
}